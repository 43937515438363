/**
* Template Name: Sailor - v4.3.0
* Template URL: https://bootstrapmade.com/sailor-free-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Nunito", sans-serif;
    color: #001233; font-size: 1.2em;
  }
  
  a {
    text-decoration: none;
    color: #444444;
    transition: color 0.3s ease-in-out;
  }
  
  a:hover {
    color: #e24d55;
    text-decoration: none;
  }
  
  
  h1, h2, h3, h4, h5, h6 {
      color: #122f53;
      font-family: "Montserrat", sans-serif;

  }
  h4 {color: #FFFF;}
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #e24d55;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: orange;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: transparent; /* Change the background color to your preference */
    transition: all 0.5s;
    z-index: 997;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%; /* Ensure the header spans the entire width */
}  

#header.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 12px 0;
}

#header.header-inner-pages {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}

#header .logo a {
    color: #fff; /* Change the text color to your preference */
}

#header .logo img {
    max-height: 40px;
}

/* Rest of your navigation styles... */


/* Navigation Menu */
.navbar {
    padding: 0;
}

/* Style the dropdown menu */
/* Style the dropdown menu */
.dropdown-menu {
  background-color: #E7473C; /* Background color */
  border: 1px solid rgba(0, 0, 0, 0.15); /* Border */
  border-radius: 5px; /* Border radius */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* Box shadow */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
  transform-origin: top; /* Transform origin */
  opacity: 0; /* Initially hidden */
  transform: translateY(-10px); /* Initially move up */
}

.dropdown-menu:hover {
  opacity: 1; /* Show the dropdown */
  transform: translateY(0); /* Move back to original position */
    top: 100%;
    visibility: visible;
}

/* Style the dropdown items */
.dropdown-menu .dropdown-item {
  padding: 10px 20px; /* Add padding */
  font-size: 15px; /* Font size */
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s; /* Smooth transition */
}

/* Hover effect for dropdown items */
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #f8f9fa; /* Hover background color */
  color: #001233; /* Hover text color */
}

/* Active link styling */
.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: #ffffff; /* Active link text color */
  background-color: #001233; /* Active link background color */
}

/* Show dropdown menu on hover */
.dropdown:hover .dropdown-menu {
  opacity: 1; /* Show on hover */
  transform
  : translateY(0); /* Move to original position */
}

/* Style the dropdown items */
.dropdown-menu .dropdown-item {
  padding: 10px 20px; /* Add padding */
  font-size: 15px; /* Font size */
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s; /* Smooth transition */
}

/* Hover effect for dropdown items */
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #f8f9fa; /* Hover background color */
  color: #001233; /* Hover text color */
}

/* Active link styling */
.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: #ffffff; /* Active link text color */
  background-color: #001233; /* Active link background color */
}

/* Show dropdown menu on hover */
.dropdown:hover .dropdown-menu {
  opacity: 1; /* Show on hover */
  transform: translateY(0); /* Move to original position */
  visibility: visible; /* Ensure visibility */
}

.navbar-nav .show .dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.navbar-nav .show .dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
}

/* Mobile view styles */
.navbar-toggler {
  transition: transform 0.3s ease-in-out;
}

.navbar-collapse.collapse.show .navbar-toggler {
  transform: translateX(-15px);
}

#header .container {
  padding: 5px 20px;
  background-color:  #E7473C;
  border-radius: 45px;
  margin: 15px 15px;
  

}
@media (max-width: 767px) {
  #header .container {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Montserrat", sans-serif;


    font-size: 15px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #001233;
}

.navbar .getstarted {
  padding: 17px 40px;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
  background-color: #001233;
  color: white;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
}

/* Add padding-bottom for the button when the navbar expands
@media (max-width: 767px) {
  .navbar-collapse.show{
    padding-bottom: 15px;
    
  }
} */


.navbar .getstarted:hover {
  letter-spacing: 3px;
  background-color: white;
  color: #001233;
  box-shadow: #001233 0px 7px 29px 0px;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #E7473C;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: #001233;
}

.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #001233;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

/* Mobile Navigation */
.mobile-nav-toggle {
    color: #556270;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }
    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(63, 73, 83, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #556270;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: #001233;
}

.navbar-mobile .getstarted {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: #001233;
}

.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

/* ----------------------------------------------------
CTA Section
---------------------------------------------------- */

.cta-section {
  background-color:  #f5f5f5;
  padding: 20px 0;
  text-align: center;
}

.cta-container {
  max-width: 800px;
  margin: 0 auto;
}

.cta-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.cta-container p {
  font-size: 16px;
  margin-bottom: 30px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-button {
  background-color: #E7473C;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

.cta-button:hover {
  background-color: #001233;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow intensity on hover */
}

/* ---------------------------------------------------
Accom Section
--------------------------------------------------- */
.unique-section {
  background-color: #f9f9f9;
  padding: 20px 0;
  text-align: center;
}

.unique-container {
  max-width: 800px;
  margin: 0 auto;
}

.unique-container h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0px;
}

.unique-container p {
  font-size: 16px;
  margin-bottom: 30px;
}

.unique-section,
.section-title,
.section-description {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.visible {
  opacity: 1;
}


.initiatives {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.initiative-item {
  perspective: 1000px;
  flex: 1 1 250px;
  max-width: 300px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.initiative-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.card {
  position: relative;
  width: 100%;
  height: 200px;
  transform-style: preserve-3d;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
}

.card-front{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
}
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  box-sizing: border-box;
}

.card-front {
  background-color: #fff;
}

.card-back {
  background-color: #fff;
  transform: rotateY(180deg);
}

.card-back img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  clip-path: inset(0);
}

.initiative-item:hover .card {
  transform: rotateY(180deg);
}

/* Media query for screens smaller than 768px (mobile) */
@media screen and (max-width: 767px) {
  .unique-container {
    padding: 0 20px;
  }
}

/* 
----------------------------------------------------------------
Testimonial section
---------------------------------------------------------------- */

.testimonials-section {
  background-color:  #f9f9f9;
  padding: 20px 0;
  text-align: center;
}

.testimonials-container {
  max-width: 800px;
  margin: 0 auto;
}

.testimonials-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.testimonial {
  margin-bottom: 30px;
}

.testimonial-content {
  background-color: #001233;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-content p {
  font-size: 16px;
  margin-bottom: 10px;
}

.testimonial-content cite {
  font-style: italic;
}


  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100vh;
    background-color:#001233;
    overflow: hidden;
    position: relative;
  }
  
  #hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  #hero .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  #hero .carousel-item::before {
    content: '';
    background-color: rgba(30, 35, 40, 0.6);
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    top: 50px;
    left: 50px;
    right: 50px;
  }
  
  #hero .container {
    text-align: center;
  }
  
  #hero h2 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 48px;
    font-weight: 700;
    opacity: 0;
            animation: leftSideAni 1s ease forwards;
            

  }
  
  #hero p {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
    opacity: 0;
            animation: topSideAni 1s ease forwards;
            animation-delay: 0.5s;
            

  }
  
  #hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
  }
  
  #hero .carousel-inner .carousel-item,
  #hero .carousel-inner .active.carousel-item-start,
  #hero .carousel-inner .active.carousel-item-end {
    opacity: 0;
  }
  
  #hero .carousel-inner .active,
  #hero .carousel-inner .carousel-item-next.carousel-item-start,
  #hero .carousel-inner .carousel-item-prev.carousel-item-end {
    opacity: 1;
    transition: 0.5s;
  }
  
  #hero .carousel-inner .carousel-item-next,
  #hero .carousel-inner .carousel-item-prev,
  #hero .carousel-inner .active.carousel-item-start,
  #hero .carousel-inner .active.carousel-item-end {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
    background: none;
    font-size: 30px;
    line-height: 0;
    width: auto;
    height: auto;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    transition: 0.3s;
    color: rgba(255, 255, 255, 0.5);
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #hero .carousel-control-next-icon:hover, #hero .carousel-control-prev-icon:hover {
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.8);
  }
  
  #hero .carousel-indicators li {
    cursor: pointer;
    background: #fff;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    opacity: .6;
    transition: 0.3s;
  }
  
  #hero .carousel-indicators li.active {
    opacity: 1;
    background: #E7473C;
  }
  
  #hero .btn-get-started {
    font-family: "Nunito", sans-serif;

    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 32px;
    border-radius: 20px; /* Adjust the value for your desired roundness */
    transition: 0.5s;
    line-height: 1;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #E7473C;
    opacity: 0;
            animation: navani 1s ease forwards;
            animation-delay: 1s;

}
  
  
  #hero .btn-get-started:hover {
    letter-spacing: 3px;
    background-color: white;
    color: hsl(0, 0%, 0%);
    box-shadow: rgb(0, 0, 0) 0px 7px 29px 0px;
  }
  
  @media (max-width: 992px) {
    #hero {
      height: 100vh;
    }
    #hero .carousel-container {
      top: 8px;
    }
  }
  
  @media (max-width: 768px) {
    #hero h2 {
      font-size: 28px;
    }
  }
  
  @media (min-width: 1024px) {
    #hero .carousel-control-prev, #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 20px 0;
    overflow: hidden;
    background-color: #f9f9f9;
  }
  
  .section-bg {
    background-color: #f8f9fa;
  }
  .icon-box {background-color: #122f53; color: #fff;}
  
  
  
  .section-title {
    padding-bottom: 40px;
  }
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Montserrat", sans-serif;

  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #e6636a;
    margin: 4px 10px;
  }
  
  .section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #556270;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  /* .breadcrumbs {
    padding: 18px 0;
    background: #f8f9fa;
    min-height: 40px;
    margin-top: 80px;
  } */

  #breadcrumbs {
    padding-top: 500px;
    padding: 18px 0;
    padding-top: 180px;
    background-color: #f8f9fa;
    min-height: 80px; /* Increased min-height to cover the header */
    margin-top: -80px; /* Negative margin to cover the header */
    position: relative; /* Added position relative */
    z-index: 996; /* Lower z-index to ensure it's behind the header */
}
  
  .breadcrumbs h2 {
    font-size: 32px;
    font-weight: 300;
    margin: 0;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6b7b8d;
    content: "/";
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 58px;
    }
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs h2 {
      margin-bottom: 10px;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h2 {
    background-color:  #f9f9f9;
    color: #001233;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .about .content h3 {
    font-weight: 500;
    line-height: 32px;
    font-size: 24px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 10px 0 0 28px;
    position: relative;
  }
  
  .about .content ul i {
    left: 0;
    top: 7px;
    position: absolute;
    font-size: 20px;
    color: #001233;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }

 /* Container Styles */
.custom-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9; /* Light background color */
  padding: 20px; /* Add padding for space around content */
}

/* Flexbox Row */
.custom-row {
  display: flex;
  flex-wrap: wrap;
}

/* Column Styles for Large Screens */
.custom-col-lg-6 {
  width: 50%;
  padding: 0 15px;
}

/* About Section Title */
.custom-about-title {
  font-size: 30px;
  font-weight: bold;
  color: #001233; /* Dark blue title */
}

/* About Section Description */
.custom-about-description {
  font-size: 18px;
  color: #001233; /* Dark blue text */
  margin-bottom: 20px;
}

/* About Section List */
.custom-about-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

/* List Items in About Section */
.custom-about-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

/* Icon Styles in About Section */
.custom-about-list li i {
  margin-right: 10px;
  color: #E7473C; /* Red icon color */
}

/* Team Description */
.custom-about-team {
  font-size: 16px;
  color: #888; /* Light gray team text */
  margin-bottom: 30px;
}

/* Link Button Styles */
.custom-about-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #E7473C; /* Red button background */
  color: #fff; /* White button text */
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Hover Effect for Link Button */
.custom-about-link:hover {
  background-color: #001233; /* Dark blue hover color */
  color: #fff;
}

/* Stats Section Styles */
.custom-about-stats {
  background-color: #fff; /* White background */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
}

/* Stats Section Title */
.custom-about-stats h3 {
  font-size: 24px;
  color: #001233; /* Dark text color */
  margin-bottom: 20px;
}

/* Stats List Styles */
.custom-stats-list {
  list-style: none;
  padding: 0;
}

/* Individual Stat Item */
.custom-stats-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

/* Value of Stat */
.custom-stats-value {
  font-size: 30px;
  font-weight: bold;
  color: #E7473C; /* Red stat value */
  margin-right: 10px;
}

/* Label for Stat */
.custom-stats-label {
  font-size: 16px;
  color: #001233; /* Dark gray label text */
}

 
  @media (max-width: 768px) {
    .custom-col-lg-6 {
      width: 100%;
      padding: 0 15px;
    }
  
    .custom-about-title {
      font-size: 28px;
      text-align: center;
      color: #001233;
    }
  
    .custom-about-description {
      font-size: 16px;
      color: #001233;
      margin-bottom: 15px;
    }
  
    .custom-about-list li {
      font-size: 14px;
      margin-bottom: 8px;
    }
  
    .custom-about-team {
      font-size: 14px;
      margin-bottom: 20px;
    }
  
    .custom-about-link {
      padding: 8px 16px;
      font-size: 14px;
      margin-bottom: 40px;
    }
  
    .custom-about-stats {
      padding: 20px;
    }
  
    .custom-about-stats h3 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    .custom-stats-list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column; /* Display stats in a column */
    }
  
    .custom-stats-list li {
      text-align: center; /* Center-align the stats and labels */
      margin-bottom: 20px;
    }
  
    .custom-stats-value {
      font-size: 20px;
      margin-right: 8px;
    }
  
    .custom-stats-label {
      font-size: 14px;
    }
  }
  
  
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients {
    padding: 15px 0;
    text-align: center;
  }
  
  .clients img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
  }
  
  .clients img:hover {
    filter: none;
    transform: scale(1.15);
  }
  
  @media (max-width: 768px) {
    .clients img {
      max-width: 40%;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    background-color: #001233; /* Dark blue background */
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    opacity: 0;
  transform: translateX(-50px); /* Start from left */
  transition: opacity 0.8s ease, transform 0.8s ease;
  }
  .services .icon-box.visible.left-slide {
    opacity: 1;
    transform: translateX(0); /* Slide in from left */
    transition: transform 0.5s ease-in-out;
  }
  
  .services .icon-box.visible.right-slide {
    opacity: 1;
    transform: translateX(0); /* Slide in from right */
    transition: transform 0.5s ease-in-out;
  }
  
  /* Slide out to the left */
  .services .icon-box.left-slide {
    transform: translateX(-5%);
  }
  
  /* Slide out to the right */
  .services .icon-box.right-slide {
    transform: translateX(5%);
  }
  
  
  .services .icon-box i {
    float: left;
    color: #001233;
    font-size: 40px;
  }
  
  .services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .icon-box h4 a {
    color: #FFFF;
    transition: 0.3s;
  }
  
  .services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .services .icon-box:hover h4 a {
    color: #E7473C;
  }

  .services .icon-box.left-slide:hover,
.services .icon-box.right-slide:hover {
    transform: translateY(-5px); /* Move box up on hover */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

  
  /* Hover Effect on Link */
  .services .icon-box:hover h4 a {
    color: #E7473C; /* Red link color on hover */
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
  }
  
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    color: #fff;
    background: #001233;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(85, 98, 112, 0.6);
  }
  
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(85, 98, 112, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  
  .portfolio .portfolio-wrap img {
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-style: italic;
  }
  
  .portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a {
    color: rgba(255, 255, 255, 0.6);
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    color: white;
  }
  
  .portfolio .portfolio-wrap:hover::before {
    opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover img {
    transform: scale(1.2);
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #001233;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #001233;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(85, 98, 112, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Our Team
  --------------------------------------------------------------*/
  .team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
    padding: 30px;
    border-radius: 4px;
    background: white;
  }
  
  .team .member .pic {
    overflow: hidden;
    width: 140px;
    border-radius: 4px;
  }
  
  .team .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team .member:hover img {
    transform: scale(1.1);
  }
  
  .team .member .member-info {
    padding-left: 30px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #556270;
  }
  
  .team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team .member span::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #dee2e6;
    bottom: 0;
    left: 0;
  }
  
  .team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    background: #8795a4;
  }
  
  .team .member .social a i {
    color: #fff;
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team .member .social a:hover {
    background: #001233;
  }
  
  .team .member .social a + a {
    margin-left: 8px;
  }
  
  /*--------------------------------------------------------------
  # Our Skills
  --------------------------------------------------------------*/
  .skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }
  
  .skills .progress .skill {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #556270;
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  
  .skills .progress-bar-wrap {
    background: #edeff1;
  }
  
  .skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: .9s;
    background-color: #6b7b8d;
  }
  
  /*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features {
    overflow: hidden;
  }
  
  .features .nav-tabs {
    border: 0;
  }
  
  .features .nav-link {
    border: 0;
    padding: 12px 0px 12px 0;
    transition: 0.3s;
    color: #556270;
    border-radius: 0;
    border-right: 2px solid white;
    font-weight: 600;
    font-size: 15px;
  }
  
  .features .nav-link:hover {
    color: #001233;
  }
  
  .features .nav-link.active {
    color: #001233;
    border-color: #001233;
  }
  
  .features .tab-pane.active {
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
  }
  
  .features .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #556270;
  }
  
  .features .details p {
    color: #777777;
  }
  
  .features .details p:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 992px) {
    .features .nav-link {
      border: 0;
      padding: 0px;
    }
    .features .nav-link.active {
      color: #fff;
      background: #001233;
    }
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
  }
  
  .pricing h4 {
    font-size: 36px;
    color: #001233;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  
  .pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing ul li {
    padding-bottom: 16px;
  }
  
  .pricing ul i {
    color: #001233;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }
  
  .pricing .btn-buy {
    background: #001233;
    display: inline-block;
    padding: 8px 35px 9px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .btn-buy:hover {
    background: #e1444d;
  }
  
  .pricing .featured h3 {
    color: #fff;
    background: #001233;
  }
  
  .pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #001233;
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-item {
    margin: 20px 0;
    padding: 20px 0;
    border-bottom: 1px solid white;
  }
  
  .faq .faq-item i {
    color: #dee2e6;
    font-size: 20px;
    float: left;
    line-height: 0;
    padding: 13px 0 0 0;
    margin: 0;
  }
  
  .faq .faq-item h4 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin: 0 0 10px 28px;
    font-family: "Poppins", sans-serif;
  }
  
  .faq .faq-item p {
    font-size: 15px;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 40px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #f8d1d3;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px 0 0 0;
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #556270;
    float: left;
    width: 44px;
    height: 44px;
    background: #edeff1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #556270;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #8795a4;
  }
  
  .contact .info .email, .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #556270;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #001233;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type="submit"] {
    padding: 17px 40px;
    border-radius: 50px;
    cursor: pointer;
    border: 0;
    background-color: #001233;
    color: white;
    box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 15px;
    transition: all 0.5s ease;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    letter-spacing: 3px;
    background-color: #E7473C;
    color: #001233;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  /* Updated Facility Management Section Styles */
/* Blog.css */

.blog {
  padding: 15px 0;
  background-color: #f8f9fa;
}

.entry-single {
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.entry-title a {
  color: #333;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  display: block;
  transition: background-color 0.3s ease;
}

.entry-title a:hover {
  background-color: #f0f0f0;
}

.entry-content {
  margin-top: 0px;
  padding: 20px;
  padding-top: 0%;
  font-size: 18px;
  line-height: 1.6;
}

blockquote {
  margin: 20px 0;
  padding: 20px;
  background-color: #f5f5f5;
  border-left: 5px solid #E7473C;
  color: #333;
  font-style: italic;
}

.sidebar {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.post-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.post-item img {
  max-width: 100px;
  height: auto;
  margin-right: 20px;
  border-radius: 5px;
}

.post-item h4 a {
  color: #333;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.post-item h4 a:hover {
  color: #E7473C;
}

.post-item.active h4 a {
  display: inline-block; /* Ensure the link takes only the necessary width */
  color: #E7473C;
  text-decoration: none;
  position:relative;
  padding-left: 20px;
  line-height: 2.0;
}

.post-item.active h4 a::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 12px; /* Adjust border-width for the arrow direction */
  border-color: transparent transparent transparent #E7473C; /* Adjust border-color for the arrow direction */
  position: absolute;
  left: 0; /* Position the arrow at the end of the link */
  top: 50%;
  transform: translateY(-50%);
}



/* Blog.css */

.image-collage {
  margin-top: 10px;
  text-align: center;
}

.image-collage h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.collage-item {
  position: relative;
  overflow: hidden;
}

.collage-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.collage-item:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    background: #001233;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(252, 251, 251, 0.1);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #E7473C;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #FFFF;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #E7473C;
  }
  
  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
  }
  
  #footer .footer-top .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #001233;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }
  
  #footer .footer-top .footer-newsletter form input[type="submit"]:hover {
    background: #df3740;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  #footer .credits a {
    color: white;
    transition: 0.3s;
    font-weight: 600;
  }
  
  #footer .credits a:hover {
    color: #E7473C;
  }

  .footer-email {
    color: #e24d55; /* Default color */
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-email:hover {
    color: orange; /* Hover color */
    text-decoration: None; /* Underline on hover, adjust as needed */
  }
  



  /* Animations added */

  @keyframes leftSideAni {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes navani {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rightSideAni {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes topSideAni {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* @keyframes ImgAni {
0% {
transform: translateX(0);
}
50% {
transform: translateX(-9px);
}
100% {
transform: translateX(0);
}
} */
@keyframes imageAni {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Header.css */
#message-container {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  display: none;
}

.success {
  background-color: #2ecc71;
  color: #fff;
}

.error {
  background-color: #e74c3c;
  color: #fff;
}


